import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, maxLength, minLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'countries.edit',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        status: false,
        country_code: null,
        translations: [],
      },
      currentTranslations: 0,
      validationErrors: {},
    }
  },
  components: {},
  validations: {
    payload: {
      country_code: {
        required,
        minLength: minLength(1),
      },
      translations: {
        $each: {
          title: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(30)
          }
        }
      }
    }
  },
  created() {
    let countryId = parseInt(this.$route.params.countryId);
    if (!this.country || this.country.id !== countryId) {
      this.fetchCountry(countryId).then(()=>{
        this._applyData();
      })
    } else {
      this._applyData();
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      country: 'countries/country',
      isCountryLoading: 'countries/isCountryLoading',
      isCountryUpdating: 'countries/isCountryUpdating',
    }),
    ...mapGetters([
      'languages',
    ]),
    getUpperCaseCode() {
      return this.payload.country_code ? this.payload.country_code.toUpperCase() : null
    },
    countryCodeErrors() {
      let error = [];
      if (!this.$v.payload.country_code.$dirty) {
        return error;
      }
      if (!this.$v.payload.country_code.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.country_code.minLength) {
        error.push(this.$t('validationMin',{count: 1}));
      }
      if (this.validationErrors.country_code) {
        this.validationErrors.country_code.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      fetchCountry: 'countries/COUNTRIES_REQUEST_DATA_GET',
      editCountry: 'countries/COUNTRIES_REQUEST_UPDATE',
    }),
    ...mapMutations({}),
    _applyData() {
      this.payload.translations = this.languages.map(langItem => {
        let lang = this.country.translations.data.filter(translationItem => {
          return translationItem.locale === langItem.translationKey;
        })[0];

        let translationItem = {
          locale: langItem.translationKey,
          title: ''
        }

        if (lang) {
          translationItem = Object.assign(translationItem, {
            title: lang.title,
            description: lang.description,
          })
        }

        return translationItem
      });
      this.payload.status = this.country.status;
      this.payload.country_code = this.country.country_code;
    },
    translationsTitleErrors(index) {
      let error = [];
      if (!this.$v.payload.translations.$each[index].title.$dirty) {
        return error;
      }
      if (!this.$v.payload.translations.$each[index].title.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.translations.$each[index].title.minLength) {
        error.push(this.$t('validationMin',{count: 1}));
      }
      if (!this.$v.payload.translations.$each[index].title.maxLength) {
        error.push(this.$t('validationMax',{count: 30}));
      }
      return error;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        let payload = {
          status: this.payload.status,
          country_code: this.payload.country_code,
        }
        this.payload.translations.forEach(item => {
          payload[item.locale] = {
            title: item.title
          }
        });
        this.editCountry({
          countryId: this.country.id,
          payload: payload
        }).then(() => {
          this.$toasted.success(this.$t('dataUpdated'));
          this.$router.push({name: 'countries.list'}).catch(() => {console.log();});
        }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            this.validationErrors = error.response.data.errors
          }
        });
      }
    }
  }
}
